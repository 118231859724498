import axios from "axios"

// const baseUrl = axios.create({ baseURL: "https://gold-nestrettocoffee.com/dashboard" });
const baseUrl = axios.create({
  baseURL: "https://nestrettocoffee.com/dashboard/api",
})
// const baseUrl = axios.create({ baseURL: "https://gold-global.org/dashboard/" });
// const baseUrl = axios.create({ baseURL: "https://nestrettocoffee.com/dashboard" });

export default baseUrl
